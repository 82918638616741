@import "src/config/theme/vars.scss";
.content {
   background-color: #E5E5E5;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex; // align-items: center;
   justify-content: center;
   align-items: stretch;
}

.meform {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   padding: 24px;
}

.loginButton {
   background-color: #5869A6;
   color: white;
   font-weight: bold;
   align-self: center;
   margin-top: 10px;
   &:hover {
      background-color: #5869A6;
      opacity: 0.7;
   }
}

.input-field {
   margin-bottom: 12px;
}

.heading {
	padding: 24px;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.main {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   max-width: 1000px;
	background-color: #fff;
}
