@import "src/config/theme/vars.scss";
//classes are prefixed  to prevent name clashes with 
// merging styles.scss files, from the consumer pages

._fupload_uploadfooter {
   width: 100%;
   margin: 10px;
   cursor: pointer;
   display: flex;
   justify-content: center;
	align-items: center;
	background-color: #F7F8FA;
	height: 120px;
   >div {
      margin: 10px 30px;
		vertical-align: middle;
		text-align: center;
	}
	img {
		height: 30px;
	}
}

