@import "src/config/theme/vars.scss";
//classes are prefixed with '_btn_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._btn_root {
   min-width: 64px;
   min-height: 36px;
   padding: 8px 16px;
	border-radius: 1px;
	user-select: none;
   border: 0px;
   color: $offColor;
   margin: 8px;
   background-color: $primaryColor;
   transition: background-color, box-shadow, opacity 0.2s ease;
	opacity: 1;
	&:not([disabled]){
		cursor: pointer;
	}
   &[disabled] {
      background-color: #cfcfcf;
      &:hover {
         opacity: 1;
         background-color: #cfcfcf;
      }
   }
   &[raised="true"] {
      box-shadow: 1px 1px 3px 0px $shadowColor;
   }
   &:hover {
      @include breakpoint($gtsm) {
         opacity: 0.6;
      }
   }
   &[variant="circle"] {
      border-radius: 100px;
      min-width: 36px;
      min-height: 36px;
      width: 36px;
      height: 36px;
      padding: 0px;
   }
   &[theme="primary"] {
      background-color: $primaryColor;
      color: $offColor;
   }
   &[theme="secondary"] {
      background-color: $secondaryColor;
      color: $secondaryOffColor;
   }
}
