@import "src/config/theme/vars.scss";
div.root {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   >div {
      width: 100%;
   }
   @include breakpoint($gtsm) {
      margin: 60px;
      width: 500px;
      ;
   }
}

div.modal-header {
   border: none
}

.content {
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
}

.header {
   display: flex;
   justify-content: space-between;
   padding: 8px;
   align-items: flex-start;
   @include breakpoint($gtsm) {
      justify-content: flex-start;
      padding: 8px 24px;
      >:last-child {
         margin-left: auto;
      }
   }
}

div.select {
   color: #333;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   max-width: 300px;
}

.areadescript {
   padding: 16px;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   align-content: center;
   flex-wrap: wrap;
   @include breakpoint($ltxs) {
      justify-content: center;
   }
}

.main {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   flex-direction: column;
   align-items: stretch;
}

.create-btn {
   background-color: #3BB273;
}

.template-row-root {
   padding: 4px;
   flex-direction: row;
   display: flex;
}

.template-schedules {
   margin-left: 12px;
   >i {
      color: #777;
   }
}
.template-schedules-multiselect {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
}

.floorinput {
   width: 100%;
   border-bottom: 1px solid #F5F5F5;
   >input {
      font-size: 16px;
      padding: 16px;
      border: 0;
   }
}

.typea-error {
   padding: 2px 14px;
}

.datetime {
   display: flex;
}

.date {
   padding: 30px;
   background-color: #37415E;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   justify-content: center;
   align-items: center;
   color: #fff;
   @include breakpoint($ltxs) {
      padding: 30px 8px;
   }
}

.time {
   padding: 30px;
   background-color: $primaryColor;
   flex: 4; @include ifIE11() {flex: 4 1 auto;}
   display: flex;
   justify-content: center;
   align-items: center;
   color: #fff;
   @include breakpoint($ltxs) {
      padding: 30px 8px;
   }
}

.form {
   padding: 16px;
   overflow: auto; -webkit-overflow-scrolling: touch;;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
}

.repeat-wrap {
   display: flex;
   padding: 0px 0px 8px 0px;
}

.repeat-input {
   width: 40px;
}

div.noflex {
   flex: 0; @include ifIE11() {flex: 0 1 auto;}
}

.repeat-tabs {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   padding-left: 12px;
   display: flex;
   align-items: stretch;
   flex-direction: column;
   flex-wrap: wrap;
}

.repeat-title {
   padding-top: 8px;
}

.line {
   border-bottom: 1px solid #F1F1F1;
   margin: 8px -16px 8px -16px;
}

.week-input {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   align-items: stretch;
   flex-direction: column;
}

.input-error {
   color: red;
   font-size: 14px;
}

.margin-bot {
   margin-bottom: 8px;
}

div.btn-list-container {
   flex-direction: row;
   align-items: center;
   flex-wrap: wrap;
   @include breakpoint($ltxs) {
      justify-content: center;
      >button {
         margin: auto;
      }
   }
}

.flex1 {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
}

.icons-wrap {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 12px;
   >img {
      &:not(:last-child) {
         margin-right: 4px;
      }
      height: 30px;
   }
}

.footer {
   display: flex;
   padding: 8px;
   justify-content: flex-end;
}

div.tabclass {
   color: #333;
   &[isselected="true"] {
      color: #333;
   }
}

.confirm-delete-body {
   padding: 24px;
   overflow: auto; -webkit-overflow-scrolling: touch;;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   >div {
      display: flex;
      align-items: center;
      padding-top: 16px;
      margin: 0px -8px;
      justify-content: flex-end;
   }
}

.areaname {
   color: #777;
   margin-right: 8px;
}
