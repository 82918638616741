@import "src/config/theme/vars.scss";
//classes are prefixed with '_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._root {
   user-select: none;
   position: absolute;
   transform: scale(0, 0);
   transform-origin: top left;
	transition: transform 0.05s ease;
	pointer-events: none;
   &[open] {
      transform: scale(1, 1);
   }
}

.base {
   background-color: #fff;
   padding: 8px;
   border-radius: 3px;
   box-shadow: 1px 1px 3px #aaa;
}
