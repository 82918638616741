@import "src/config/theme/vars.scss";
//classes are prefixed with '_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._root {
   user-select: none;
   min-width: 150px;
}

.close-area {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   display: none;
   &[open] {
      display: inherit;
   }
}

._title {
   display: flex;
   justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

._menu-wrap {
   position: fixed;
   transform: scale(0, 0);
   transform-origin: top left;
   transition: transform 0.05s ease;
   &[open] {
      transform: scale(1, 1);
   }
}

._row {
   background-color: #fff;
   display: flex;
   align-items: center;
   justify-content: flex-start;
	padding: 4px 16px;
	@include breakpoint($ltsm) {
		padding: 16px;
	}
   transition: background-color 0.3s ease;
	min-height: 25px;
   &:not(:last-child) {
      border-bottom: 1px solid #eee;
   }
   &:hover, &:focus {
      background-color: #F2F2F2;
   }
}

._menu {
   padding: 2px;
   background-color: #fff;
   box-shadow: 1px 1px 5px #333;
   display: flex;
   flex-direction: column;
   align-items: stretch;
   margin: 5px 0px;
	border-radius: 1px;
	max-height: 500px;
	overflow: auto; -webkit-overflow-scrolling: touch;;
}

.none {
   color: #999;
}
