@import "src/config/theme/vars.scss";
//classes are prefixed with '_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
@mixin _responsive {
   ._theadrow {
      &._responsive-hide-header {
         display: none;
      }
   }
   ._td-content {
      justify-content: space-between;
   }
   ._tbodyrow {
      display: flex;
      flex-wrap: wrap;
      >td {
         flex: 1 1 100%;
      }
      &:not([st-last="true"]) {
         >td:last-child {
            border-bottom: 1px solid #E6E6E6;
         }
      }
      &[st-first="true"] {
         margin-bottom: 10px;
      }
      &[st-last="true"] {
         margin-top: 10px;
      }
      &:not([st-first="true"][st-last="true"]) {
         margin-top: 10px;
         margin-bottom: 10px;
      }
   }
   ._responsive-header[st-hide="false"] {
      display: flex;
   }
}

._table {
   border-collapse: collapse;
   border-spacing: 0;
   &._responsive-table {
      @include breakpoint($ltxs) {
         &.xs {
            @include _responsive;
         }
      }
      @include breakpoint($ltsm) {
         &.sm {
            @include _responsive;
         }
      }
      @include breakpoint($ltmd) {
         &.md {
            @include _responsive;
         }
      }
      @include breakpoint($ltlg) {
         &.lg {
            @include _responsive;
         }
      }
   }
}

._td>._td-content>._responsive-header {
   display: none;
}

._th {
   user-select: none;
   padding: 0;
   /* removing will cause inf loop on collapsable tables*/
   box-sizing: border-box;
   &[issorted="true"] {
      font-weight: bold;
      color: $primaryColor;
	}
	&[sortable]{
		cursor: pointer;
	}
}

._tbodyrow {
   background-color: #FFF;
}

._td {
   padding: 0;
   /* removing will cause inf loop on collapsable tables*/
   box-sizing: border-box;
}

._th-content {
   display: flex;
   justify-content: flex-start;
   align-items: stretch;
}

._td-content {
   display: flex;
   justify-content: flex-start;
   align-items: stretch;
}

._pagination-wrap {
   display: flex;
   align-items: center;
   justify-content: center;
   >button {
      background-color: transparent;
      color: inherit;
   }
   >button[disabled] {
      background-color: transparent;
      color: #aaa;
      &:hover {
         opacity: 1;
         background-color: transparent;
      }
   }
}

._pagination-text {
   user-select: none;
}

._collapse-wrap {
   display: flex;
   align-items: stretch;
   >[st-scrollable="true"] {
      overflow: auto; -webkit-overflow-scrolling: touch;;
   }
}

._sticky-head {
   position: fixed;
   display: none;
}
