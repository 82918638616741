@import "src/config/theme/vars.scss";
$sideWidth: 250px;
div.root {
	/*NO CSS TRANSFORM*/
   flex: 1;
   display: flex; // align-items: center;
   justify-content: center;
   align-items: stretch;
   overflow: hidden;
   flex-direction: column;
}

.calendar-wrap {
   position: absolute;
   display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: stretch;
   overflow: auto; -webkit-overflow-scrolling: touch;;
   background-color: #fff;
   transform: translateZ(0); // make gpu render element
   top: -1000px;
   left: 70px;
   box-shadow: 1px 1px 3px $shadowColor;
	z-index: 99;
	* {
		font-size: 12px;
	}
   @include breakpoint($ltsm) {
      right: 0;
      left: 0;
   }
   >.calender {
      flex-shrink: 0;
		max-width: 100%;
		width: 100%;
   }
   &[isopen="true"] {
      top: 70px;
      @include breakpoint($ltsm) {
         top: 0;
         bottom: 0;
      }
   }
   transition:top 0.5s;
   .drpCloseButtonContainer {
      display: flex;
      justify-content: flex-end;
   }
   :global(.rdrDefinedRangesWrapper) {
      @include breakpoint($ltxs) {
         display: none;
      }
   }
}

.header {
   background-color: $primaryColor;
   height: 70px;
   z-index: 10;
   display: flex;
   align-items: center;
   >button {
      background-color: #fff;
      color: #333;
      box-shadow: 1px 1px 3px #333 !important;
   }
   @include breakpoint($ltsm) {
      justify-content: center;
      >button {
         display: none;
      }
   }
   @include breakpoint($ltxs) {
      flex-wrap: wrap;
      height: initial;
   }
}

.side {
   width: $sideWidth;
   background-color: #fff;
   box-shadow: 1px 1px 30px $shadowColor;
   /*use opacity to hide box shadow when menu is
   //closed. */
   opacity: 1;
   transition: opacity 0.5s;
   &:not([open]) {
      @include breakpoint($ltsm) {
         opacity: 0;
      }
   }
   height: 100%;
   box-sizing: border-box;
   z-index: inherit;
   .side-content {
      overflow: auto; -webkit-overflow-scrolling: touch;;
      padding: 16px;
      max-height: calc( 100% - 100px);
   }
}

.drawer {
   box-shadow: 1px 1px 30px $shadowColor;
   z-index: 3;
   &:not([open]) {
      box-shadow: none;
   }
}

.content {
	/*NO CSS TRANSFORM*/
   flex: 1;
   display: flex;
	align-items: stretch;
	min-height: 0;
}

.main {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   overflow: auto; -webkit-overflow-scrolling: touch;;
   padding: 24px 0px;
}

.search-bar {
   background-color: #6B85CD;
   color: #fff;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   margin-right: 20px;
	@include breakpoint($ltsm) {
		margin-left: 20px;
	}
}

.header-date-time {
   width: 270px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: $offColor;
   cursor: pointer;
   align-self: stretch;
   @include breakpoint($ltxs) {
      margin-top: 8px;
		flex: 1 1 100%;
		padding: 8px 0px;
   }
}

.mobile-menu-wrap {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding: 16px;
   @include breakpoint($gtsm) {
      display: none;
   }
}

.header-tabs {
   flex: 3; @include ifIE11() {flex: 3 1 auto;}
   align-self: stretch;
   display: flex;
   justify-content: flex-end;
   align-items: stretch;
   @include breakpoint($ltsm) {
      display: none;
   }
}

.tabs {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   >div {
      padding: 0px 12px;
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
      &:not(:last-child) {
         border-right: 1px solid #7184B9;
      }
      &[isselected="true"] {
         background-color: #fff;
         color: #333;
      }
   }
}

.menu-btn-wrap {
   position: relative;
   user-select: none;
   z-index: 2;
   cursor: pointer;
   &[open] {
      @include breakpoint($gtsm) {
         display: none;
      }
   }
   >div {
      padding: 8px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
   }
}

.spinner {
   width: 40px;
   display: flex;
   margin-right: 20px;
   align-items: center;
   justify-content: center;
}

.toggle-swipes {
   padding: 16px;
   display: flex;
   align-items: center;
   border-top: 1px solid #ECECEC;
   color: #888888;
}
