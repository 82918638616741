@import "src/config/theme/vars.scss";
div.root {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex; // align-items: center;
   justify-content: center;
   align-items: stretch;
   overflow: hidden;
   flex-direction: column;
   padding: 12px 16px;
}

.fields {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   overflow: auto; -webkit-overflow-scrolling: touch;;
}

.footer {
   display: flex;
   justify-content: flex-end;
}

.search {
   border-bottom: 1px solid #F6F6F6;
   margin-bottom: 8px;
}

.error {
   padding: 16px;
}
