@import "src/config/theme/vars.scss";
//classes are prefixed with _sb_ to prevent name classes on merge
._sb_icon {
   color: inherit
}

._sb__input {
   width: 100%;
   border: 0;
   background-color: inherit;
   color: inherit;
   caret-color: inherit;
}

._sb__input::placeholder {
   color: inherit;
   opacity: 0.5;
}

._sb__inputWrap {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   padding: 4px 12px;
   height: 30px;
}
