$primaryColor: #556CAB;
$offColor: #FFFFFF;
$secondaryColor:#37415E;
$secondaryOffColor:#F5F8FF;
$shadowColor: #DBE0EC;

// export these vars to javascript via webpack
:export {
   primaryColor: $primaryColor;
   offColor: $offColor;
   secondaryColor: $secondaryColor;
   shadowColor: $shadowColor;
   secondaryOffColor: $secondaryOffColor;
}