@import "src/config/theme/vars.scss";
.content {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   padding: 16px;
}

.console {
   margin: 24px;
   background-color: white;
   box-shadow: 1px 1px 1px 1px inset #aaa;
   align-self: stretch;
   overflow: auto; -webkit-overflow-scrolling: touch;;
   padding: 8px;
   height: 450px;
}

.line-green {
   color: green;
}

.line-black {
   color: #333;
}

.line {
   min-height: 20px;
}

.line-error {
   color: red;
}

.line-yellow{
   color:rgb(112, 112, 10);
}

.wraps{
   display: flex;
   justify-content: flex-end;
   align-items: center;
   padding: 0 24px  24px 24px;
   > *{
      margin:0 12px;
   }
}
