@import "src/config/theme/vars.scss";
$rowHeight: 35px;
:export {
   rowHeight: $rowHeight;
}

._calandar_closearea {
   position: fixed;
   top: 0px;
   left: 0px;
   right: 0px;
   bottom: 0px;
   display: none;
}

._calandar_root {
   position: relative;
   user-select: none;
   background-color: $primaryColor;
   overflow: hidden;
   &[isloaded="false"] {
      visibility: hidden;
      >._calandar_body {
         transition: none;
      }
   }
}

._calandar_header {
   display: flex;
   justify-content: center;
   align-items: stretch;
   flex-direction: column;
}

._calandar_dayHeader {
   background-color: $primaryColor;
   color: #97A5CB;
   >table {
      border-spacing: 0;
      border-collapse: collapse;
      width: 100%;
   }
   th {
      vertical-align: center;
      text-align: center;
      padding: 4px;
      &[isday="true"] {
         color: $offColor;
      }
   }
}

._calandar_dateheader-header {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: $primaryColor;
   color: $offColor;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   >svg {
      padding: 12px;
      cursor: pointer;
   }
   >div {
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
      text-align: center;
   }
}

._calandar_body {
   /*NO CSS TRANSFORM*/
   overflow: auto;
   table-layout: fixed;
   transition: height 0.5s ease;
   background-color: $offColor;
   position: relative;
   >table {
      border-spacing: 0;
      border-collapse: collapse;
      width: 100%;
   }
   &[shrinkonmobile="false"] {
      transition: none;
   }
}

._calandar_row {
   >td {
      height: $rowHeight;
      width: calc(100% / 7);
   }
}

._calandar_flex {
   display: flex;
}

._calandar_month-overlays {
	pointer-events: none;
   display: none;
   position: absolute;
   top: 0px;
   left: 0px;
   right: 0px;
   background-color: rgba(160, 160, 160, 0.301);
   &[show="true"] {
      display: block;
   }
}

._calandar_month-overlay {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   font-size: 16px;
   color: $offColor;
   text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
