@import "src/config/theme/vars.scss";
@import "./styles.scss";
.desktop-content {
   background-color: #EEF0F6;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex; // align-items: center;
   justify-content: flex-start;
   align-items: stretch;
}

.main {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   flex-direction: column;
   overflow: auto; -webkit-overflow-scrolling: touch;;
}

.side {
   background-color: #fff;
   box-shadow: 1px 1px 3px $shadowColor;
   display: flex;
   flex-direction: column;
   align-items: center;
   >button {
      min-width: 160px;
      &:first-of-type {
         margin-top: 26px;
      }
   }
}

@mixin _daySize {
   min-width: 300px;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
}

.day {
   @include _daySize;
   >:first-child {
      padding-top: 0;
   }
   box-sizing: border-box;
   &:not(:last-child) {
      border-right: 1px solid #E3E4E7;
   }
   &[isday="true"] {
      box-shadow: 0px 0px 16px #bbb;
   }
}

.day-header {
   height: 70px;
   color: $offColor;
	background-color: $primaryColor;
	flex-direction: column;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: bold;
   @include _daySize;
   &[isday="true"] {
		box-shadow: 0px -2px 16px #ccc;
		z-index:2;
	}
	&[ispubhol="true"] {
		background-color: rgb(129, 151, 209);
   }
}

.calender-header {
   height: 70px;
}

.switch-wrap{
	padding: 12px 0px;
}

.area-wrap {
   padding: 12px;
   width: 100%;
   max-width: 300px;
   box-sizing: border-box;
}

.add-btn {
   background-color: transparent;
   padding: 0;
   margin: 0;
   display: flex;
   align-items: center;
   justify-content: space-between;
   >img {
      height: 70px;
      margin-right: 8px;
   }
   color:#333;
}

.days-wrap {
   min-height: min-content;
   display: flex;
   justify-content: flex-start;
   align-items: stretch;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   >:first-child {
      padding-left: 12px;
   }
   >:last-child {
      padding-right: 12px;
   }
}

.days-header-wrap {
   background-color: $primaryColor;
   display: flex;
   justify-content: flex-start;
	align-items: stretch;
	flex-shrink: 0;
}
