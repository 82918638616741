@import "src/config/theme/vars.scss";
.scroller {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   overflow: auto; -webkit-overflow-scrolling: touch;;
   touch-action: pan-y;
}

.root {
   position: relative;
   height: 1010px;
   user-select: none;
   :not(input) {
      font-size: 12px
   }
}

.row {
   display: flex;
   align-items: stretch;
}

.row-main {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   margin-right: 24px;
}

.line {
   height: 20px;
   border-top: 1px solid #F2F2F2;
}

.row-date {
   width: 60px;
   text-align: right;
   padding: 0px 12px;
   box-sizing: border-box;
}

.svg {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 60px;
   right: 24px;
   height: 100%;
   width: calc(100% - 84px);
}

.blocktext {
   background-color: #88888841;
   color: #888888;
   display: flex;
   justify-content: center;
   align-items: center;
}

.floatelem {
   position: absolute;
   left: 60px;
   right: 24px;
}

.booking {
   background-color: $primaryColor;
   color: #fff;
   cursor: pointer;
   >div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      height: 100%;
      width: 100%;
   }
}

.inputwrap {
   display: flex;
   align-items: center;
   justify-content: center;
   align-self: stretch;
   padding: 0px 10px;
   >input {
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
   }
   >span {
      padding: 0px 8px;
   }
}

.textinput {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: stretch;
   >.header {
		display: flex;
		padding: 6px 0px;
      >div {
         padding: 0px 10px;
         flex: 1; @include ifIE11() {flex: 1 1 auto;}
      }
   }
   >.btn-wraps {
		padding: 22px 0px 8px 0px;
      display: flex;
      justify-content: flex-end;
      >button {
         padding: 0px 16px;
         min-height: 24px;
         color: #333;
      }
   }
}

.knob1 {
   position: absolute;
   top: -30px;
   left: 0%;
}

.knob2 {
   position: absolute;
   bottom: -30px;
   left: 0%;
}


.knob {
   height: 60px;
   width: 60px;
   display: flex;
   justify-content: center;
   align-items: center;
   >div {
      height: 16px;
      width: 16px;
      border: 2px solid #000;
      border-radius: 20px;
      background-color: #fff;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      >svg {
         width: 12px !important;
      }
   }
}


.pencil {
   position: absolute;
   bottom: -30px;
   right: 0%;
   &:focus {
      outline: 1px blue;
	}
	>div {
      height: 22px;
		width: 22px;
	}
}
