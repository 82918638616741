@import "src/config/theme/vars.scss";
$sideWidth: 250px;
div.root {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex; // align-items: center;
   justify-content: center;
   align-items: stretch;
   overflow: hidden;
   flex-direction: column;
}

.header {
   background-color: $primaryColor;
   height: 70px;
   z-index: 10;
   display: flex;
   align-items: center;
   padding: 0px 16px;
   >button {
      background-color: #fff;
      color: #333;
      box-shadow: 1px 1px 3px #333 !important;
   }
   @include breakpoint($ltsm) {
      justify-content: center;
      >button {
         display: none;
      }
   }
}

.side {
   width: $sideWidth;
   background-color: #fff;
   box-shadow: 1px 1px 30px $shadowColor;
   /*use opacity to hide box shadow when menu is
   //closed. */
   opacity: 1;
   transition: opacity 0.5s;
   &:not([open]) {
      @include breakpoint($ltsm) {
         opacity: 0;
      }
   }
   height: 100%;
   padding: 16px;
   box-sizing: border-box;
}

.header-spacer {
   width: calc(#{$sideWidth} + 52px);
   @include breakpoint($ltsm) {
      display: none;
   }
}

.content {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   align-items: stretch;
	min-height: 0;
}

.main {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   overflow: auto; -webkit-overflow-scrolling: touch;;
   padding: 24px;
}

td.td {
   padding: 8px 24px;
   vertical-align: top;
   >div {
      @include breakpoint($ltsm) {
         justify-content: flex-start !important;
      }
   }
}

th.th {
   padding: 8px 24px;
   vertical-align: top;
   white-space: nowrap;
   color: #B3B3B3;
   background-color: #fff;
   &[issorted="true"] {
      svg {
         margin-left: 6px;
      }
	}
	>div{
		align-items: center;
	}
}

.action-btn {
   margin: 0px;
}

.table-root {
   width: 100%;
   button {
      min-height: 1px;
   }
   .bodyrow {
      &:not([st-last="true"]) {
         border-bottom: 1px solid #F6F6F6;
      }
   }
}

.footer {
   background-color: #fff;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   >button:first-of-type {
      margin-left: 24px;
   }
}

div.largemodal {
   min-width: 1200px;
   @include breakpoint($ltmd) {
      min-width: 100%;
      width: 100%;
   }
   >div {
      width: 100%;
   }
}

div.smallmodal {
   @include breakpoint($ltxs) {
      min-width: 100%;
      width: 100%;
   }
   >div {
      width: 100%;
      min-width: 300px;
   }
}

.rows-selector {
   display: flex;
   align-items: center;
   @include breakpoint($ltsm) {
      justify-content: center;
   }
   .rows-select {
      min-width: 50px;
      >div {
         justify-content: center;
         >div {
            flex: 1; @include ifIE11() {flex: 1 1 auto;}
            text-align: center;
         }
      }
   }
}

.edit-modal {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   padding: 32px;
}

.menu-btn-wrap {
   position: relative;
   user-select: none;
   cursor: pointer;
   &[open] {
      @include breakpoint($gtsm) {
         display: none;
      }
   }
   >div {
      padding: 8px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
		top: 0;
		z-index: 5;
      bottom: 0;
   }
}

.hidesm {
   @include breakpoint($ltsm) {
      display: none !important;
   }
}

.search-bar {
   background-color: #6B85CD;
   color: #fff;
}

.mobile-menu-wrap {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding: 16px;
   @include breakpoint($gtsm) {
      display: none;
   }
}

.sub-items {
   >div {
      flex-wrap: wrap;
      min-width: 100px;
   }
   span {
      padding: 4px 8px;
      &:not(:last-of-type):not(.area) {
         border-right: 1px solid #F6F6F6;
      }
      &.area {
         color: #999
      }
   }
}

.th-padding {
   >div {
      padding: 4px 0px;
   }
}

.drawer {
   box-shadow: 1px 1px 30px $shadowColor;
   &:not([open]) {
      box-shadow: none;
   }
}

.grey {
   color: #777;
}
