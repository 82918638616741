@import "src/config/theme/vars.scss";
.root {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   flex-direction: column;
}

.chart-wrap {
   padding: 8px 24px;
   @include breakpoint($gtsm) {
      height: 320px;
   }
}

.chart-switch {
   margin-left: 46px;
   z-index: 2;
   @include breakpoint($ltsm) {
      position: initial;
      margin-bottom: 12px;
   }
}

.table-wrap {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
}

.table-root {
   width: 100%;
}

td.td {
   vertical-align: top;
   >div {
      padding: 16px 24px;
   }
   &:not(.th-spacer) {
      border-bottom: 1px solid #ECECEC;
   }
}

th.th {
   padding: 8px 24px;
   vertical-align: top;
   white-space: nowrap;
   background-color: #E2EBFF;
   &[issorted="true"] {
      svg {
         margin-left: 6px;
      }
   }
}

.th-spacer {
   width: 50px;
   >div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 4px;
   }
   @include breakpoint($ltsm) {
      width: 0px;
      >div {
         display: none;
      }
   }
}

.rows-selector {
   display: flex;
   align-items: center;
   padding-left: 72px;
   @include breakpoint($ltsm) {
      justify-content: center;
      padding-left: 0;
   }
   .rows-select {
      min-width: 50px;
      >div {
         justify-content: center;
         >div {
            flex: 1; @include ifIE11() {flex: 1 1 auto;}
            text-align: center;
         }
      }
   }
}
