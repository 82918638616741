@import "src/config/theme/vars.scss";
.header {
   // background-color: $primaryColor;
   background-color: #37415E; // box-shadow: 0px 1px 3px #aaa;
   //zindex allows box shadow without margin
   // z-index: 2;
}

.header-content {
   flex-direction: row;
   justify-content: center;
   align-items: stretch;
   display: flex;
   min-height: 70px;
}

.reload-bar {
   background-color: yellow;
   display: flex;
   justify-content: center;
   font-weight: bold;
   align-items: center;
   height: 60px;
   cursor: pointer;
}

.user-icon-wrap {
   color: $offColor;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 0px 24px;
   .user-icon {
      height: 38px;
      width: inherit;
      color: $primaryColor;
   }
}

.main {
   flex: 2; @include ifIE11() {flex: 2 1 auto;}
   display: flex;
   align-items: stretch;
   justify-content: center;
   padding-top: 13px;
   >div {
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
      max-width: 300px;
   }
}

.tabs {
   display: flex;
   align-items: stretch;
   div {
      align-items: stretch;
      display: flex;
   }
}

.namewrap {
   display: flex;
   align-items: center;
   justify-content: center;
}

.username {
   color: #E55387
}

.logo {
   padding: 24px;
   display: flex;
   height: 70px;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   >img {
      height: 46px;
      margin: 0px 12px;
   }
   >div {
      color: #fff;
      font-weight: bold;
		font-size: 18px;
		letter-spacing: 1px;
   }
   @include breakpoint($ltsm) {
      margin: 0px;
      padding: 0px;
   }
}

.tab-icon {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   >svg {
      width: inherit !important;
      height: 30px;
      margin: auto;
   }
}

.menubtn {
   >img {
      height: 25px;
      margin: 0px 12px;
   }
   height: 70px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.select-menu {
   >:last-child {
      padding-bottom: 12px;
   }
}

.tabsclass {
	padding: 0px 16px;
	>div[content]{
		padding: 26px 8px;
		justify-content: flex-start;
	}
   &[isselected="true"] {
      color: $offColor;
      background-color: $primaryColor;
   }
}

.tabs{
	margin-right: 20px;
}
