@import "src/config/theme/vars.scss";
//classes are prefixed with '_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._template-root {
   padding: 4px;
   vertical-align: top;
}

._root {
   display: flex;
   align-items: center;
   >svg {
      margin: 0px 14px 0px 8px;
   }
}

:global {
   .typeahead-underlined-text {
      border-bottom: 3px solid red;
   }
}

._popup-root {
   position: absolute;
   background-color: #fff;
   box-shadow: 1px 1px 3px $shadowColor;
   overflow: auto; -webkit-overflow-scrolling: touch;;
   display: none;
   &[open] {
      display: initial;
   }
}

._popup-row {
	cursor: pointer;
   padding: 4px;
   &[isfocused="true"] {
      background-color: #eee;
   }
}
._popup-row-multiselect {
   display: flex;
   flex-direction: row;
}
.row-multiselect {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   text-align: right;
   padding-right: 8px;
   font-weight: 400;
}
.multiselect-done-button {
   text-align: center;
    font-size: 14px;
    padding: 8px 4px;
    font-weight: 200;
    background-color: #3BB273;
    color: #FFFFFF;
    cursor: pointer;
    user-select: none;
}
.multiselect-done-button:hover {
   background-color: #26a361;
}

._input {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   border: none;
   box-sizing: border-box;
   min-width: 150px;
   border-bottom: 1px solid #333;
}

.close-area {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   display: none;
   &[open] {
      display: inherit;
   }
}
