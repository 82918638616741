@import "src/config/theme/vars.scss";
//classes are prefixed with '_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._root {
   user-select: none;
   min-width: 150px;
   position: relative;
   color: $offColor;
}

.close-area {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
}

._title {
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
   padding: 16px;
   color: inherit
}

._menu-wrap {
   height: 0px;
   transition: height 0.05s ease;
   overflow: auto; -webkit-overflow-scrolling: touch;;
   &[open] {
      height: inherit;
   }
}

._row {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   color: inherit;
   background-color: transparent;
   padding: 4px 16px;
   @include breakpoint($ltsm) {
      padding: 16px;
   }
   transition: background-color 0.3s ease;
   min-height: 25px;
   &:hover,
   &:focus {
      @include breakpoint($gtsm) {
         background-color: #F2F2F2;
      }
   }
}

._menu {
   padding: 2px;
   display: flex;
   flex-direction: column;
   align-items: stretch;
   margin: 5px 0px;
   border-radius: 1px;
   max-height: 500px;
   overflow: auto; -webkit-overflow-scrolling: touch;;
}

.none {
   color: inherit;
}
