@import "src/config/theme/vars.scss";
.icon {
   height: 55px;
   user-select: none;
   cursor: pointer;
}

.area {
   background-color: #fff;
   box-shadow: 1px 1px 3px $shadowColor;
}

.sch-header {
   font-size: 16px;
   display: flex;
   align-items: center;
   padding: 12px;
   user-select: none;
   cursor: pointer;
   >svg {
      margin-left: 12px;
      color: #777;
      cursor: pointer;
   }
}

.circle {
   background-color: $primaryColor;
   color: $offColor;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50px;
   height: 25px;
   width: 25px;
}

.flex1 {
   flex: 1; //ifIE11 isnt auto added when this file is imported into other css files add manually
   @include ifIE11() {
      flex: 1 1 auto;
   }
}

.schedule-header {
   padding: 8px;
   background-color: #E2EBFF;
   display: flex;
   justify-content: space-between;
   align-items: center;
   >.small-add-btn {
      display: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      min-height: 10px;
      min-width: 20px;
      align-items: center;
      justify-content: center;
      margin: -8px 0px;
   }
   &:hover {
      >.small-add-btn {
         @include breakpoint($gtsm) {
            display: flex;
            >img {
               height: 24px;
            }
         }
      }
   }
}

.bookings-wrap {
   padding: 0px 16px;
}

.footer-bar {
   position: fixed;
   bottom: 0px;
   right: 0px;
   padding: 12px;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}

.booking {
   display: flex;
   align-items: stretch;
   justify-content: flex-end;
	padding: 8px 0px;
	&[error]{
		color:red;
	}
   &[isswipe="false"] {
      cursor: pointer;
   }
   &:not(:last-child) {
      border-bottom: 1px solid #F4F4F4;
   }
   >.times {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      >div {
         padding: 8px 0px;
         width: 55px;
         @include breakpoint($ltsm) {
            //font size goes up on mobile
            width: 70px;
         }
         color: #aaa;
         &[isday="true"] {
            color: inherit
         }
      }
      >:first-child {
         border-bottom: 1px solid #F4F4F4;
      }
   }
   >.name {
      width: 80px;
      border-left: 1px solid #F4F4F4;
      display: flex;
      justify-content: center;
      align-items: center;
   }
   >.bubblething {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 8px;
      >div {
         border: 1px solid #404A65;
         border-radius: 10px;
         margin: 2px;
         &:first-child,
         &:last-child {
            border: 2px solid #404A65;
            height: 3px;
            width: 3px;
         }
      }
   }
   >.icons-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1; //ifIE11 isnt auto added when this file is imported into other css files
      @include ifIE11() {
         flex: 1 1 auto;
      }
      user-select: none;
      >img {
         &:not(:last-child) {
            margin-right: 4px;
         }
         height: 30px;
      }
   }
}

.repeats {
   padding: 8px;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 16px;
   >img {
      user-select: none;
      height: 16px;
   }
}

.swipecard {
   height: 20px;
}