@import "src/config/theme/vars.scss";
._calandar_day_root {
   vertical-align: top;
   text-align: center;
   box-sizing: border-box;
   position: relative;
   padding: 8px;
   color: #888;
   &[ismonth="true"] {
      color: #333;
   }
   &[disabled] {
      color: rgb(216, 216, 216);
      background-color: rgb(243, 243, 243);
   }
}

._calandar_day_selected {
   position: absolute;
   bottom: 0px;
   left: 0px;
   right: 0px;
   height: 4px;
   background-color: $primaryColor;
}

._calandar_day_mark {
   position: absolute;
   bottom: 4px;
   left: 0px;
   right: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
   >div {
      height: 1px;
      width: 1px;
      border: 1px solid $primaryColor;
      background-color: $primaryColor;
      border-radius: 5px;
   }
}
