@import "src/config/theme/vars.scss";
.content {
   background-color: $secondaryColor;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex; // align-items: center;
   justify-content: center;
   align-items: stretch;
   flex-direction: column;
}

.wraps {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex; // align-items: center;
   justify-content: center;
   align-items: stretch;
   flex-direction: column;
}

.title {
   padding: 46px 0px 10px 0px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 18px;
}

.card {
   background-color: #D4D5DA;
   padding: 16px 28px;
   box-sizing: border-box;
   align-items: stretch;
   max-width: 360px;
   width: 100%;
   @include breakpoint($ltsm) {
      padding: 16px 0px;
   }
   @include breakpoint($ltxs) {
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
   }
}

.card-wrap {
   flex: 4; @include ifIE11() {flex: 4 1 auto;}
   display: flex;
   align-items: center;
   flex-direction: column;
}

.meform {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   padding: 24px;
   display: flex;
   flex-direction: column;
   align-items: stretch;
}

.resetform {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   padding: 24px;
   display: flex;
   flex-direction: column;
   align-items: stretch;
}

.end {
   display: flex;
   justify-content: flex-end;
}

button.loginButton {
   background-color: #3BB273;
   color: white;
   font-weight: bold;
   margin: 8px 0px;
   &[raised="true"] {
      box-shadow: 1px 1px 15px #C2C6D3;
   }
}

button.resetButton {
   background-color: transparent;
   color: #333;
   align-self: center;
   text-decoration: underline;
   margin: 8px 0px;
   font-weight: bold;
}

.input-field {
   margin-bottom: 12px;
}

.spinner {
   align-self: center;
}

.logo {
   padding: 24px;
   display: flex;
   height: 70px;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   flex-direction: column;
   >img {
      height: 46px;
      margin: 4px;
   }
   >div {
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1px;
   }
   @include breakpoint($ltsm) {
      flex: inherit;
      margin: 0px;
      padding: 0px;
   }
}
