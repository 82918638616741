@import "config/theme/theme.scss";
$xsmall: 600;
$small: 960;
$medium: 1280;
$large: 1920;
:export {
   xsmall: $xsmall;
   small: $small;
   medium: $medium;
   large: $large;
}

$gtxs: "(min-width: #{$xsmall}px)";
$gtsm: "(min-width: #{$small}px)";
$gtmd: "(min-width: #{$medium}px)";
$gtlg: "(min-width: #{$large}px)";
$ltxs: "(max-width: #{$xsmall - 1}px)";
$ltsm: "(max-width: #{$small - 1}px)";
$ltmd: "(max-width: #{$medium - 1}px)";
$ltlg: "(max-width: #{$large - 1}px)";
$md: "(min-width: 1280px) and (max-width: 1919px)";
$sm: "(min-width: 960px) and (max-width: 1279px)";
@mixin breakpoint($point) {
   @media #{$point} {
      @content ;
   }
}

@mixin ifIE11() {
   @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @content ;
   }
}