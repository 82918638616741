@import "src/config/theme/vars.scss";
div.root {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex; // align-items: center;
   justify-content: center;
   align-items: stretch;
   overflow: hidden;
   flex-direction: column;
   padding: 12px 16px;
}

.inputfield {
   margin: 8px;
}

.fields {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   overflow: auto; -webkit-overflow-scrolling: touch;;
}

.footer {
   display: flex;
   justify-content: flex-end;
}

.multichevron {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   align-items: center;
   >div {
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
   }
}

.user-row {
   display: flex;
	flex-wrap: wrap;
	padding: 8px 0px;
   &:not(:last-of-type) {
      border-bottom: 2px solid #F6F6F6;
   }
   label {
      white-space: nowrap;
   }
}

.cross{
	padding:12px;
	cursor: pointer;
}
