@import "src/config/theme/vars.scss";
.wraps {
   height: 100%;
   width: 100%;
   display: flex;
	flex-direction: column;
	max-height: 100%;
   max-width: 100%;
}

.content-wraps {
	/*NO CSS TRANSFORM*/
   flex: 1;
   display: flex;
   justify-content: flex-start;
	align-items: stretch;
	min-height: 0;
}

.content-class {
	/*NO CSS TRANSFORM*/
   flex: 1;
   display: flex;
   overflow: hidden;
   background-color: #fff;
   >div {
      overflow: auto; -webkit-overflow-scrolling: touch;;
   }
}

.portal {
   height: 0px;
   width: 0px;
}

.splashroot {
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex;
   align-items: center;
	justify-content: center;
	background-color: #37415E;
   >img {
      height: 100px;
   }
}
