@import "src/config/theme/vars.scss";
//classes are prefixed with '_' to prevent name clashes with 
// merging styles.scss files, from the consumer pages
._root {
   user-select: none;
   display: flex;
   align-items: stretch;
   overflow: auto; -webkit-overflow-scrolling: touch;;
}

._tab {
   display: flex;
   align-items: stretch;
   flex-direction: column;
   color: $offColor;
   cursor: pointer;
   &[isselected="true"] {
      color: $secondaryOffColor; 
      >._tab-footer {
         background-color: $primaryColor;
      }
   }
   >._tab-content {
      padding: 8px;
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: inherit;
   }
   >._tab-footer {
      background-color: inherit;
      height: 4px;
   }
   &:hover>._tab-footer {
      background-color: $primaryColor;
   }
}
