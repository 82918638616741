@import "src/config/theme/vars.scss";
@import "./styles.scss";
.mobile-content {
   background-color: #EEF0F6;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   display: flex; // align-items: center;
   justify-content: flex-start;
   align-items: stretch;
   flex-direction: column;
}

.mobile-header {
   padding: 4px;
   padding-bottom: 0px;
   display: flex;
   align-items: center;
   >svg {
      margin-left: 16px;
   }
}

.add-btn {
   background-color: transparent;
   padding: 0;
   margin: 0;
   >img {
      height: 70px;
   }
}

.switch {
   flex-shrink: 1;
   >label {
      font-size: 12px;
      user-select: none;
   }
}

.areas-wrap {
   display: flex;
   flex-direction: column;
   align-content: flex-start;
   flex: 1; @include ifIE11() {flex: 1 1 auto;}
   margin-top: -12px;
   justify-content: flex-start;
   align-items: center;
}

.area-wrap {
   padding: 12px 0px;
   width: 100%;
   max-width: 300px;
   box-sizing: border-box;
}

.main {
   flex: 1; @include ifIE11() {flex: 1 1 auto;} // display: flex;
   // flex-direction: column;
}

.pub-hol-tooltip {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.pub-icon {
   padding: 0px 8px;
}

.pubhol-text {
   font-size: 12px;
   color: #666;
   margin-bottom: 4px;
}
