@import "src/config/theme/vars.scss";


td.calender-day {
	padding: 0px;
   >[label] {
      height: calc(100% - 8px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 4px;
   }
   &[selectedday="true"] {
      >[label] {
         background-color: $primaryColor;
         border-radius: 60px;
         color: $offColor;
      }
   }
   &:not([ismonth]) {
      background-color: $secondaryOffColor;
   }
}

