@import "src/config/theme/vars.scss";
div.new-modal-root {
   width: 100%;
   max-width: 460px;
   display: flex;
   flex-direction: column;
   @include breakpoint($gtsm) {
      margin: 20px;
   }
   >div {
      background-color: #fff;
		flex: 1; @include ifIE11() {flex: 1 1 auto;}
		display: flex;
		flex-direction: column;
   }
}

.new-modal-header {
   background-color: #37415E;
   padding: 8px 16px;
   color: #fff;
   display: flex;
   justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
}

.new-modal-header-buttons {
	flex-shrink: 0;
   background-color: #37415E;
   color: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
   user-select: none;
   >div {
      padding: 16px;
      flex: 1; @include ifIE11() {flex: 1 1 auto;}
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: inherit;
      cursor: pointer;
      &[isselected="true"] {
         background-color: $primaryColor;
      }
   }
}

.new-modal-content {
   padding: 16px;
}

.tick {
   background-color: #fff;
   color: $primaryColor;
}

.justifstart {
   justify-content: flex-start;
}


